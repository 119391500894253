import { useQuery } from '@tanstack/react-query';

import { OrderManagerReviewApi } from '../api/OrderManagerReviewApi.api';

const QUERY_KEY_OM_ADMIN_VALUATION_HISTORY =
  'QUERY_KEY_OM_ADMIN_VALUATION_HISTORY';
export const useOmAdminOrderItemValuationHistory = (orderItemId: number) => {
  return useQuery(
    [QUERY_KEY_OM_ADMIN_VALUATION_HISTORY, orderItemId],
    async () => {
      return await OrderManagerReviewApi.fetchOmAdminOrderItemValuationHistory(
        orderItemId,
      );
    },
  );
};
