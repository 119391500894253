import React from 'react';

import { SubNav } from '@hcs/design-system';
import { VIEW_PATHS_AGILE_OPS_COMMAND } from '@hcs/hc-internal-apps';

const SUB_NAV_ITEMS_CONFIG = [
  {
    label: 'Order Items',
    dataHcName: 'order-items',
    navigateTo: VIEW_PATHS_AGILE_OPS_COMMAND.REVIEW_QUEUE,
    children: [
      {
        label: 'Review Queue',
        navigateTo: VIEW_PATHS_AGILE_OPS_COMMAND.REVIEW_QUEUE,
        dataHcName: 'review-queue',
      },
      {
        label: 'All Addresses',
        navigateTo: VIEW_PATHS_AGILE_OPS_COMMAND.ALL_ADDRESSES,
        dataHcName: 'all-addresses',
      },
    ],
  },
];

const dataHcName = 'organization-left-nav';
export const AgileOpsNav = () => {
  return (
    <SubNav
      dataHcName={`${dataHcName}-sub-nav`}
      subNavItemsConfig={SUB_NAV_ITEMS_CONFIG}
    />
  );
};
