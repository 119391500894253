import React from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';

import { Button } from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { NoDataIcon } from '@hcs/design-system';
import { OrderItemActivity } from '@hcs/types';

import { showDate } from '../../utils/omAdminOrderItems.utils';
import { OmAdminOrderItemActivityLogNewMessageLauncher } from '../OmAdminOrderItemActivityLogNewMessage';

import { ActivityItemComment } from './ActivityItemComment';
import { ActivityItemNonComment } from './ActivityItemNonComment';

import styles from './OmAdminOrderItemActivityLog.module.css';

interface Props {
  activities: OrderItemActivity[];
  orderItemId: number;
  isFetching: boolean;
}

const dataHcName = 'activity-items';
export const ActivityItems = ({
  activities,
  orderItemId,
  isFetching,
}: Props) => {
  return (
    <>
      {isFetching ? (
        <LoadingSpinner
          dataHcName={`${dataHcName}-skeleton`}
          small
          absoluteCenter
        />
      ) : null}
      {!isFetching ? (
        <>
          <div className={styles.ActivityItems} data-hc-name={`${dataHcName}`}>
            {activities && activities?.length > 0 ? (
              activities?.map((activity: OrderItemActivity, idx: number) => {
                const showDateItem: boolean = showDate(
                  activity.timestamp,
                  activities[idx - 1]?.timestamp,
                );
                return (
                  <div
                    key={activity.id}
                    data-hc-name={dataHcName}
                    className={classNames(styles.ActivityItem, {
                      [styles.dateVisible]: showDate,
                    })}
                  >
                    {showDateItem ? (
                      <div
                        className={styles.Date}
                        data-hc-name={`${dataHcName}-date`}
                      >
                        {format(new Date(activity.timestamp), 'E LLL, d')}
                      </div>
                    ) : null}
                    {activity.eventType === 'comment' ? (
                      <ActivityItemComment
                        activity={activity}
                        orderItemId={orderItemId}
                      />
                    ) : (
                      <ActivityItemNonComment activity={activity} />
                    )}
                  </div>
                );
              })
            ) : (
              <div className={styles.NoActivity}>
                <NoDataIcon size="xl" color="neutral-dark-20" />
                <p data-hc-name={`${dataHcName}-no-activity`}>
                  No Activity Items for that Type
                </p>
              </div>
            )}
          </div>

          <OmAdminOrderItemActivityLogNewMessageLauncher
            activityLogNewMessageProps={{
              orderItemId,
            }}
            button={
              <Button dataHcName={`${dataHcName}-new-message-button`}>
                New Message
              </Button>
            }
          />
        </>
      ) : null}
    </>
  );
};
