import React from 'react';

import { UserMenuAvatarButton, UserMenuLogoutButton } from '@hcs/authn';
import {
  UserMenu,
  UserMenuButton,
  UserMenuButtonProps,
  UserMenuButtons,
} from '@hcs/design-system';
import { useOmAdminLogout } from '@hcs/order-manager-admin';

interface Props {
  buttonGroups?: UserMenuButtonProps[][];
  triggerClassName?: string;
  onLogout?: VoidFunction;
}
const dataHcName = 'partner-tools-user-menu';
export const PartnerToolsUserMenu = ({
  buttonGroups,
  triggerClassName,
  onLogout,
}: Props) => {
  const omAdminLogoutMutation = useOmAdminLogout();
  return (
    <UserMenu
      triggerClassName={triggerClassName}
      content={
        <>
          <UserMenuAvatarButton />
          {buttonGroups?.map((buttonGroup, g) => {
            return (
              <UserMenuButtons
                dataHcName={`${dataHcName}-app-group-${g}`}
                key={`group-${g}`}
              >
                {buttonGroup.map((button, b) => {
                  return (
                    <UserMenuButton
                      {...button}
                      key={`group-${g}-button-${b}`}
                    />
                  );
                })}
              </UserMenuButtons>
            );
          })}
          <UserMenuButtons dataHcName={`${dataHcName}-user-buttons`}>
            <UserMenuLogoutButton
              dataHcName={`${dataHcName}-logout`}
              onLogout={() => {
                omAdminLogoutMutation.mutate();
                onLogout?.();
              }}
            />
          </UserMenuButtons>
        </>
      }
    />
  );
};
