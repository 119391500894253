import { useQuery } from '@tanstack/react-query';

import { OrderManagerReviewApi } from '../api/OrderManagerReviewApi.api';

const QUERY_KEY_OM_ADMIN_ACTIVITY_COMMENT =
  'QUERY_KEY_OM_ADMIN_ACTIVITY_COMMENT';
export const useOmAdminOrderItemActivityComment = (
  orderItemId: number,
  commentId?: number,
) => {
  return useQuery(
    [QUERY_KEY_OM_ADMIN_ACTIVITY_COMMENT, orderItemId, commentId],
    async () => {
      if (commentId) {
        return await OrderManagerReviewApi.fetchOmAdminOrderItemCommentById(
          orderItemId,
          commentId,
        );
      }

      return null;
    },
  );
};
