import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useToastSlice } from '@hcs/toast';
import {
  UseOmAdminOrderItemRemoveCompsOptions,
  UseOmAdminOrderItemRemoveCompsPayload,
} from '@hcs/types';

import { OrderManagerReviewApi } from '../api/OrderManagerReviewApi.api';

export const useOmAdminOrderItemRemoveComps = (
  options?: UseOmAdminOrderItemRemoveCompsOptions,
) => {
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation<
    void,
    AxiosError,
    UseOmAdminOrderItemRemoveCompsPayload,
    void
  >(
    async ({ orderItemId, hcAddressIds }) => {
      return await OrderManagerReviewApi.removeOmAdminOrderItemComps(
        orderItemId,
        hcAddressIds,
      );
    },
    {
      ...options,
      onSuccess: (response, variables) => {
        options?.onSuccess?.(response, variables);
        toastOpen({
          type: 'loading-success',
          title: 'Remove comp completed, reloading comps.',
          duration: 10000000,
        });
      },
    },
  );
};
