import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { OmAdminOrderItem, OmAdminOrderItemParams } from '@hcs/types';
import { PaginatedResponseData } from '@hcs/types';

import { OrderManagerReviewApi } from '../api/OrderManagerReviewApi.api';

export const QUERY_KEY_ORDER_ITEMS_ADMIN = 'order-items-admin';
export const useOrderItemsAdmin = (
  params?: OmAdminOrderItemParams,
  options?: UseQueryOptions<
    PaginatedResponseData<OmAdminOrderItem[]>,
    AxiosError,
    PaginatedResponseData<OmAdminOrderItem[]>,
    [typeof QUERY_KEY_ORDER_ITEMS_ADMIN, OmAdminOrderItemParams | undefined]
  >,
) => {
  return useQuery(
    [QUERY_KEY_ORDER_ITEMS_ADMIN, params],
    async ({ signal }) => {
      return OrderManagerReviewApi.fetchOrderItems(params, signal);
    },
    options,
  );
};
