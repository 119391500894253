import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { OM_REVIEWER_LOCAL_STORAGE_TOKEN_KEY } from '../constants/orderManagerAdmin.constants';

import { QUERY_KEY_OM_ADMIN_USER } from './useOmAdminUser';
import { QUERY_KEY_OM_REVIEWER_TOKEN } from './useOmReviewerToken';

export const useOmAdminLogout = () => {
  const queryClient = useQueryClient();
  return useMutation<void, AxiosError, void, void>(async () => {
    // SECURITY: localStorage is referenced to access values from global local storage @jnettleman
    localStorage.removeItem(OM_REVIEWER_LOCAL_STORAGE_TOKEN_KEY);
    queryClient.removeQueries([QUERY_KEY_OM_REVIEWER_TOKEN]);
    queryClient.removeQueries([QUERY_KEY_OM_ADMIN_USER]);
  });
};
