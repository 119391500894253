import React, { useEffect, useState } from 'react';

import { OrderItemActivity, OrderItemActivityCommentPayload } from '@hcs/types';

import { useOmAdminOrderItemActivityComment } from '../../hooks/useOmAdminOrderItemActivityComment';
import { useUpdateOrderItemCommentActionRequired } from '../../hooks/useUpdateOrderItemCommentActionRequired';

import { ActivityItem } from '.';

interface Props {
  activity: OrderItemActivity;
  orderItemId: number;
}

export const ActivityItemComment = ({ activity, orderItemId }: Props) => {
  const [activityItemComment, setActivityItemComment] =
    useState<OrderItemActivity | null>(null);

  const { data: commentData, isFetched } = useOmAdminOrderItemActivityComment(
    orderItemId,
    activity.metadata.commentId,
  );

  const buildActivity = (commentData: OrderItemActivityCommentPayload) => {
    return {
      ...activity,
      metadata: {
        ...activity.metadata,
        comment: commentData.comment,
        isActionRequired: commentData.isActionRequired,
        xmlMessageId: commentData.xmlMessageId,
      },
    };
  };

  useEffect(() => {
    if (commentData) {
      //fnma comment
      setActivityItemComment(buildActivity(commentData));
    } else {
      // internal comment
      setActivityItemComment(activity);
    }
  }, [commentData]);

  const { mutate: orderItemActionRequiredMutation } =
    useUpdateOrderItemCommentActionRequired();

  useEffect(() => {
    if (isFetched && activityItemComment?.metadata.isActionRequired) {
      orderItemActionRequiredMutation({
        orderItemId,
        commentId: activityItemComment.metadata.commentId || null,
        xmlMessageId: activityItemComment.metadata.xmlMessageId || '',
        comment: activityItemComment.metadata.comment || '',
      });
    }
  }, [isFetched, activityItemComment]);

  return <ActivityItem activity={activityItemComment} />;
};
