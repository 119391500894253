import { EntryPageContainer } from '@hcs/design-system';
import { LayoutBanner, LayoutBannerMobile } from '@hcs/design-system';
import { ExperienceFlag } from '@hcs/experience-flags';
import { useScreenSize } from '@hcs/hooks';
import { SearchReportSelect } from '@hcs/report-api';

import PexpIconPng from '../../../../assets/images/pexp-icon.png';
import { LegacyPexpRedirect } from '../../navigation/LegacyPexpRedirect';
import { PexpNav } from '../../navigation/PexpNav';

export const PexpRootPage = () => {
  const { isPhoneWidth } = useScreenSize();
  return (
    <ExperienceFlag
      experienceFlagId="PEXP_REPORT_API"
      next={
        isPhoneWidth ? (
          <LayoutBannerMobile title="Access home values and market insights" >
            <SearchReportSelect />
          </LayoutBannerMobile>
        ) : (
          <>
            <PexpNav />
            <EntryPageContainer>
              <LayoutBanner
                bannerContent={<img alt="" src={PexpIconPng} />}
                title="Access home values and market insights"
              />
            </EntryPageContainer>
          </>
        )
      }
      now={<LegacyPexpRedirect />}
    />
  );
};

export default PexpRootPage;
