import React from 'react';

import { Button } from '@hcs/design-system';
import { TableCell } from '@hcs/design-system';
import { OmValuationHistoryItem, TableCellProps } from '@hcs/types';

import { useOmAdminOrderItemPdfDownload } from '../../../hooks/useOmAdminOrderItemPdfDownload';

import { ValuationHistoryItem } from '.';

const dataHcName = 'om-admin-order-item-valuation-history-table-cell';

const Display = ({ valuationHistoryItem }: ValuationHistoryItem) => {
  const { refetch } = useOmAdminOrderItemPdfDownload(
    valuationHistoryItem.download.orderItemId,
    valuationHistoryItem.download.orderId,
    false,
  );

  const downloadPdf = () => {
    refetch().then((pdfData) => {
      if (pdfData.data?.url) {
        fetch(pdfData.data?.url).then((pdf) => {
          pdf.blob().then((blob) => {
            const a = document.createElement('a');
            // Create a DOMString representing the blob and point the link element towards it
            a.href = URL.createObjectURL(blob);
            a.setAttribute(
              'download',
              `${valuationHistoryItem.download.addressSlug}-${valuationHistoryItem.reportDate}.pdf`,
            );
            a.click();
          });
        });
      }
    });
  };

  return (
    <div data-hc-name={dataHcName}>
      <Button
        dataHcName={`${dataHcName}-download-pdf-button`}
        onClick={downloadPdf}
        disabled={valuationHistoryItem.download.disabled}
      >
        Download PDF
      </Button>
    </div>
  );
};

const ContentCell = ({
  valuationHistoryItem,
  ...tableCellProps
}: {
  valuationHistoryItem: OmValuationHistoryItem;
}) => {
  return (
    <TableCell {...tableCellProps}>
      <Display valuationHistoryItem={valuationHistoryItem} />
    </TableCell>
  );
};

const defaultContentProps: Partial<TableCellProps> = {
  isTableCell: true,
};

ContentCell.defaultProps = defaultContentProps;

export const VALUATION_HISTORY_ITEM_DOWNLOAD_CONFIG = {
  ContentCell,
  Display,
  label: 'Download',
};
