import { useEffect, useState } from 'react';

import { CompsTabData } from '@hcs/types';

import { pinkmanCompToFlatPropertyDetails } from '../utils/orderItemReport.utils';

import { useOmAdminOrderItemReport } from './useOmAdminOrderItemReport';

export const useOmAdminOrderItemCompetitiveListings = (orderItemId: number) => {
  const [compsData, setCompsData] = useState<CompsTabData[] | undefined>(
    undefined,
  );
  const orderItemReportQuery = useOmAdminOrderItemReport(orderItemId);

  useEffect(() => {
    if (orderItemReportQuery.isInitialLoading && orderItemReportQuery.data) {
      setCompsData(undefined);
    } else {
      const comps = orderItemReportQuery.data?.competitiveListings.map(
        pinkmanCompToFlatPropertyDetails,
      );
      setCompsData(comps);
    }
  }, [
    orderItemReportQuery.isInitialLoading,
    orderItemReportQuery.data?.competitiveListings,
  ]);

  return {
    ...orderItemReportQuery,
    data: compsData,
  };
};
