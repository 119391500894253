import { useQuery } from '@tanstack/react-query';

import { OrderManagerReviewApi } from '../api/OrderManagerReviewApi.api';

export const QUERY_KEY_ADMIN_INSPECTION_FORM =
  'QUERY_KEY_ADMIN_INSPECTION_FORM';
export const useOmAdminOrderItemInspectionForm = (inspectionId: number) => {
  return useQuery([QUERY_KEY_ADMIN_INSPECTION_FORM, inspectionId], async () => {
    return await OrderManagerReviewApi.fetchOmAdminOrderItemInspectionForm(
      inspectionId,
    );
  });
};
