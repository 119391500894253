import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { Button } from '@hcs/design-system';
import { Checkbox } from '@hcs/design-system';
import {
  Table,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from '@hcs/design-system';
import { LoadingSpinner } from '@hcs/design-system';
import { NoDataIcon } from '@hcs/design-system';
import { useActiveState } from '@hcs/hooks';
import { COMP_FIELDS_CONFIG } from '@hcs/property-state';
import { PROPERTY_STATE_FIELD_CONFIGS } from '@hcs/property-state';
import { ADDRESS_CONFIG } from '@hcs/property-state';
import { useToastSlice } from '@hcs/toast';
import { CompDetailsTypes, CompsTabData } from '@hcs/types';
import { CompFields, PropertyStateFields, PropertyStateType } from '@hcs/types';
import { formatMoney } from '@hcs/utils';
import { combineUseQueryResult } from '@hcs/utils';

import { useOmAdminOrderItemCompetitiveClosedSales } from '../../hooks/useOmAdminOrderItemCompetitiveClosedSales';
import { useOmAdminOrderItemCompetitiveListings } from '../../hooks/useOmAdminOrderItemCompetitiveListings';
import { useOmAdminOrderItemDisabled } from '../../hooks/useOmAdminOrderItemDisabled';
import { useOmAdminOrderItemRemoveComps } from '../../hooks/useOmAdminOrderItemRemoveComps';
import { OmAdminOrderItemRemoveCompDialog } from '../OmAdminOrderItemRemoveCompDialog';

import { CompCheckbox } from './CompCheckbox';

import styles from './OmAdminOrderItemCompsDetails.module.css';

interface Props {
  className?: string;
  orderItemId: number;
  compDetailsType: CompDetailsTypes;
}

const dataHcName = 'order-item-comps-table';
export const CompsDetailsTable = ({
  className,
  orderItemId,
  compDetailsType,
}: Props) => {
  const [compsData, setCompsData] = useState<CompsTabData[] | null>(null);
  const [removableComps, setRemovableComps] = useState<number[] | null>(null);

  const {
    active: activeRemoveCompsDialog,
    handleOpen: handleOpenRemoveCompsDialog,
    handleClose: handleCloseRemoveCompsDialog,
  } = useActiveState();

  const competitiveClosedSalesQuery =
    useOmAdminOrderItemCompetitiveClosedSales(orderItemId);
  const competitiveListingsQuery =
    useOmAdminOrderItemCompetitiveListings(orderItemId);

  const { data: orderItemCompetitiveListings } = competitiveListingsQuery;
  const { data: orderItemCompetitiveClosedSales } = competitiveClosedSalesQuery;

  const combinedQuery = combineUseQueryResult([
    competitiveClosedSalesQuery,
    competitiveListingsQuery,
  ]);

  const { data: orderItemDisabled } = useOmAdminOrderItemDisabled(orderItemId);

  const toggleChange = (v: boolean, hcAddressId: number | null) => {
    if (v) {
      setRemovableComps(
        !hcAddressId ? null : [...(removableComps || []), hcAddressId],
      );
    } else {
      setRemovableComps(
        removableComps?.filter((id) => id !== hcAddressId) || [],
      );
    }
  };

  const handleSelectAll = (v: boolean) => {
    if (v) {
      setRemovableComps(
        compsData?.map((comp) => comp.address.hcAddressId) || [],
      );
    } else {
      setRemovableComps(null);
    }
  };

  useEffect(() => {
    if (orderItemCompetitiveClosedSales && compDetailsType === 'compsSold') {
      setCompsData(orderItemCompetitiveClosedSales);
      setRemovableComps(null);
    }
    if (orderItemCompetitiveListings && compDetailsType === 'compsListing') {
      setCompsData(orderItemCompetitiveListings);
      setRemovableComps(null);
    }
  }, [
    compDetailsType,
    combinedQuery.isFetched,
    orderItemCompetitiveClosedSales,
    orderItemCompetitiveListings,
  ]);
  const selectAll = useMemo(() => {
    return compsData && removableComps
      ? removableComps?.length === compsData?.length
      : false;
  }, [compsData, removableComps]);

  const {
    actions: { toastOpen },
  } = useToastSlice();
  const removeCompsMutation = useOmAdminOrderItemRemoveComps({
    onSuccess: () => {
      handleCloseRemoveCompsDialog();
      setRemovableComps(null);
      toastOpen({
        type: 'success',
        title: 'Comps Removed Successfully',
      });
    },
    onError: () => {
      handleCloseRemoveCompsDialog();
      toastOpen({
        type: 'error',
        title: 'Error Removing Comps',
      });
    },
  });

  const isLoading = combinedQuery.isFetching || removeCompsMutation.isLoading;
  const isFetched = combinedQuery.isFetched && !combinedQuery.isFetching;
  const viewingCompsListing =
    !isLoading && isFetched && compDetailsType === 'compsListing';
  const viewingCompsSold =
    !isLoading && isFetched && compDetailsType === 'compsSold';

  const isCompsListingTable =
    viewingCompsListing &&
    orderItemCompetitiveListings !== undefined &&
    !!orderItemCompetitiveListings?.length;

  const isCompsClosedSalesTable =
    viewingCompsSold &&
    orderItemCompetitiveClosedSales !== undefined &&
    !!orderItemCompetitiveClosedSales?.length;

  const isCompsListingTableNull =
    viewingCompsListing &&
    orderItemCompetitiveListings !== undefined &&
    !orderItemCompetitiveListings?.length;

  const isCompsClosedSalesTableNull =
    viewingCompsSold &&
    orderItemCompetitiveClosedSales !== undefined &&
    !orderItemCompetitiveClosedSales?.length;

  const { HeaderCell: AddressHeaderCell, ContentCell: AddressContentCell } =
    ADDRESS_CONFIG;
  const { HeaderCell: DistanceHeaderCell, ContentCell: DistanceContentCell } =
    COMP_FIELDS_CONFIG[CompFields.distance];
  const {
    HeaderCell: SimilarityHeaderCell,
    ContentCell: SimilarityContentCell,
  } = COMP_FIELDS_CONFIG[CompFields.similarity];
  const { HeaderCell: PriceHeaderCell, ContentCell: PriceContentCell } =
    compDetailsType === 'compsSold'
      ? PROPERTY_STATE_FIELD_CONFIGS[PropertyStateFields.lastClosePrice]
      : PROPERTY_STATE_FIELD_CONFIGS[PropertyStateFields.currentListingPrice];

  return (
    <>
      <Button
        dataHcName={`${dataHcName}-remove-button`}
        className={styles.OmAdminOrderItemCompsRemoveButton}
        onClick={handleOpenRemoveCompsDialog}
        disabled={!removableComps || removableComps?.length === 0}
      >
        Remove
      </Button>
      {/* Show Loader */}
      {isLoading ? (
        <LoadingSpinner dataHcName={`${dataHcName}-loading-spinner`} small />
      ) : null}
      {/* Show Table with Data */}
      {isCompsClosedSalesTable || isCompsListingTable ? (
        <>
          <Table
            skeletonConfig={{
              isLoading: !combinedQuery?.isFetched,
              colCount: 8,
              rows: 4,
            }}
            className={classNames(className)}
            dataHcName={dataHcName}
          >
            <TableHeader>
              <TableHeaderCell>
                <Checkbox
                  dataHcName={`${dataHcName}-select-all`}
                  checked={selectAll}
                  disabled={orderItemDisabled?.isNotAssignee}
                  onChange={handleSelectAll}
                />
              </TableHeaderCell>
              <DistanceHeaderCell />
              <SimilarityHeaderCell />
              <PriceHeaderCell />
              <TableHeaderCell>Adj. Value</TableHeaderCell>
              <AddressHeaderCell sticky={false} />
            </TableHeader>
            {compsData?.map((comp, i) => {
              return (
                <TableRow key={`${i}-${comp.address}`}>
                  <TableCell>
                    <CompCheckbox
                      orderItemId={orderItemId}
                      hcAddressId={comp.address.hcAddressId}
                      toggleChange={toggleChange}
                      removableComps={removableComps}
                    />
                  </TableCell>
                  <DistanceContentCell comp={comp.compFields} />
                  <SimilarityContentCell comp={comp.compFields} />
                  <PriceContentCell
                    propertyStateArgs={{
                      propertyStateType: 'flat',
                      propertyState: comp.propertyDetails,
                    }}
                  />
                  <TableCell>
                    <>
                      {formatMoney(
                        comp.propertyDetails[PropertyStateFields.currentValue],
                      )}
                    </>
                  </TableCell>
                  <AddressContentCell
                    propertyStateArgs={{
                      propertyStateType: PropertyStateType.Preview,
                      propertyState: {
                        hcAddressId: comp.address.hcAddressId,
                        location: {
                          ...comp.address,
                        },
                      },
                    }}
                    sticky={false}
                  />
                </TableRow>
              );
            })}
          </Table>
          <OmAdminOrderItemRemoveCompDialog
            orderItemId={orderItemId}
            active={activeRemoveCompsDialog}
            onClose={handleCloseRemoveCompsDialog}
            removableComps={removableComps}
            setRemovableComps={setRemovableComps}
            removeCompsMutation={removeCompsMutation}
          />
        </>
      ) : null}
      {/* Show Null States */}
      {isCompsClosedSalesTableNull || isCompsListingTableNull ? (
        <div className={styles.NullCompItems}>
          <span className={styles.CompIcon}>
            <NoDataIcon size="xl" color="neutral-dark-20" />
          </span>
          <h3>No comps available yet</h3>
        </div>
      ) : null}
    </>
  );
};
