import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useToastSlice } from '@hcs/toast';

import { OrderManagerReviewApi } from '../api/OrderManagerReviewApi.api';

import { QUERY_KEY_OM_ADMIN_REVIEW_ORDER } from './useOmAdminOrderItem';

export const useDeleteOmAdminOrderItemInspectionImage = () => {
  const queryClient = useQueryClient();
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation<void, AxiosError, number, void>(
    OrderManagerReviewApi.deleteOmAdminOrderItemInspectionImage,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY_OM_ADMIN_REVIEW_ORDER]);
        toastOpen({
          type: 'success',
          title: 'Successfully deleted inspection image',
        });
      },
      onError: (error) => {
        console.error(error);
        toastOpen({
          type: 'error',
          title: `Failed to Delete Inspection Image, ${error}`,
        });
      },
    },
  );
};
