import React, { useMemo } from 'react';

import { Dropdown } from '@hcs/design-system';

import { ACTIVITY_LOG_EVENT_TYPES } from '../../constants/orderItem.constants';

import styles from './OmAdminOrderItemActivityLog.module.css';

interface Props {
  selected?: string;
  handleSelect: (type: string | null) => void;
}

const dataHcName = 'om-admin-order-item-activity-item-dropdown';

export const ActivityItemDropdown = ({ selected, handleSelect }: Props) => {
  const ActivitySelectOptions = useMemo(
    () =>
      Object.keys(ACTIVITY_LOG_EVENT_TYPES).map((type) => {
        const activityType =
          ACTIVITY_LOG_EVENT_TYPES[
            type as keyof typeof ACTIVITY_LOG_EVENT_TYPES
          ];
        return {
          label: activityType.label,
          value: activityType.value,
        };
      }),
    [ACTIVITY_LOG_EVENT_TYPES],
  );

  return (
    <Dropdown
      dataHcName={dataHcName}
      className={styles.ActivitySelect}
      options={ActivitySelectOptions}
      onSelect={handleSelect}
      value={selected}
    />
  );
};
