import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useToastSlice } from '@hcs/toast';
import { OrderItemInspectionImagePayload } from '@hcs/types';

import { OrderManagerReviewApi } from '../api/OrderManagerReviewApi.api';

import { QUERY_KEY_OM_ADMIN_REVIEW_ORDER } from './useOmAdminOrderItem';

export interface UpdateInspectionImageResponse {
  captureTimestamp: string | null;
  createdAt: string;
  id: number;
  image: string;
  inspection: number;
  inspectionOrder: string | null;
  label: string;
  latitude: string | null;
  longitude: string | null;
  notes: string;
  updatedAt: string;
}

export const useUpdateOmAdminOrderItemInspectionImage = () => {
  const queryClient = useQueryClient();
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation<
    UpdateInspectionImageResponse,
    AxiosError<{ message: string }>,
    OrderItemInspectionImagePayload,
    void
  >(
    async (orderItemInspectionImagePayload) => {
      return await OrderManagerReviewApi.updateOmAdminOrderItemInspectionImage(
        orderItemInspectionImagePayload,
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_KEY_OM_ADMIN_REVIEW_ORDER]);
        toastOpen({
          type: 'success',
          title: 'Successfully updated inspection image',
        });
      },
      onError: (error) => {
        toastOpen({
          type: 'error',
          title: `Failed to Update Inspection Image, ${error.response?.data.message}`,
        });
      },
    },
  );
};
