import React from 'react';
import { useNavigate, useParams } from 'react-router';

import {
  APP_CONFIG_AGILE_OPS_COMMAND,
  VIEW_PATHS_AGILE_OPS_COMMAND,
} from '@hcs/hc-internal-apps';
import {
  OmAdminOrderItem,
  useOmAdminOrderItem,
} from '@hcs/order-manager-admin';

import { useNavigateBackToTable } from '../../hooks/useNavigateBackToTable';
import { GoBackNav } from '../../navigation/AgileOpsNav/GoBackNav';

export const AgileOpsOrderItemPage = () => {
  const { orderItemId } = useParams();
  const orderItemIdInt = Number(orderItemId);
  const navigate = useNavigate();
  const { data: orderItem } = useOmAdminOrderItem(orderItemIdInt);
  const { valueReportReviewPending, inspectionReviewPending } = orderItem || {};
  const navigateBackToTable = useNavigateBackToTable([
    valueReportReviewPending,
    inspectionReviewPending,
  ]);

  return (
    <OmAdminOrderItem
      orderItemId={orderItemIdInt}
      backButton={<GoBackNav orderItemId={orderItemIdInt} />}
      onRevisionSuccess={() => {
        navigate(
          `${APP_CONFIG_AGILE_OPS_COMMAND.rootPath}/${VIEW_PATHS_AGILE_OPS_COMMAND.REVIEW_QUEUE}`,
        );
      }}
      approveOrderItemProps={{
        onSuccess: navigateBackToTable,
      }}
    />
  );
};
