import { useEffect, useState } from 'react';

import { OrderItemStatus } from '@hcs/types';

import { useOmAdminOrderItem } from './useOmAdminOrderItem';
import { useOmAdminUser } from './useOmAdminUser';

export const useOmAdminOrderItemDisabled = (orderItemId: number) => {
  const [isNotAssignee, setIsNotAssignee] = useState(true);
  const [inActionable, setInActionable] = useState(true);
  const [isDisallowRevision, setIsDisallowRevision] = useState(true);
  const [orderComplete, setOrderComplete] = useState(false);
  const orderItemQuery = useOmAdminOrderItem(orderItemId);
  const { data: omAdminUser } = useOmAdminUser();

  useEffect(() => {
    if (omAdminUser?.id === orderItemQuery?.data?.assignee?.id) {
      setIsNotAssignee(false);
    } else {
      setIsNotAssignee(true);
    }
  }, [orderItemQuery, omAdminUser]);

  useEffect(() => {
    if (
      orderItemQuery?.data?.status ===
        OrderItemStatus.InspectionCorrectionReview ||
      orderItemQuery?.data?.status === OrderItemStatus.ValuationReview ||
      orderItemQuery?.data?.status === OrderItemStatus.InspectionReview
    ) {
      setInActionable(false);
    } else {
      setInActionable(true);
    }
  }, [orderItemQuery]);

  useEffect(() => {
    if (orderItemQuery?.data?.status === OrderItemStatus.Complete) {
      setOrderComplete(true);
    } else {
      setOrderComplete(false);
    }
  }, [orderItemQuery]);

  useEffect(() => {
    const pendingStates = [
      orderItemQuery?.data?.inspectionReviewPending,
      orderItemQuery?.data?.valueReportReviewPending,
    ];
    if (pendingStates.some((state) => state)) {
      setIsDisallowRevision(false);
    } else {
      setIsDisallowRevision(true);
    }
  }, [orderItemQuery]);

  return {
    ...orderItemQuery,
    data: orderItemQuery.isInitialLoading
      ? undefined
      : { isNotAssignee, inActionable, orderComplete, isDisallowRevision },
  };
};
