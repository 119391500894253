import { useCallback } from 'react';
import { Path, useNavigate } from 'react-router';

import { useExperienceFlags } from '@hcs/experience-flags';
import { APP_CONFIG_PEXP, VIEW_PATHS_PEXP } from '@hcs/hc-products';
import { CerberusInputHcs } from '@hcs/types';
import { PropertyIdentifierSearchParams } from '@hcs/types';
import { NavigateToPropertyPreviewFn } from '@hcs/types';
import { LEGACY_PEXP_UI_URL } from '@hcs/urls';
import { objectToUrlSearchParamString } from '@hcs/utils';

export const getPropertyPreviewPath = (
  cerberusInput: CerberusInputHcs,
): Partial<Path> => {
  return {
    pathname: `${APP_CONFIG_PEXP.rootPath}/${VIEW_PATHS_PEXP.SEARCH}`,
    search: objectToUrlSearchParamString({
      [PropertyIdentifierSearchParams.HcAddressId]: cerberusInput.hcAddressId
        ? cerberusInput.hcAddressId.toString()
        : undefined,
      [PropertyIdentifierSearchParams.Slug]: cerberusInput.slug,
      [PropertyIdentifierSearchParams.StreetAddress]:
        cerberusInput.streetAddress,
      [PropertyIdentifierSearchParams.Zipcode]: cerberusInput.zipcode,
      [PropertyIdentifierSearchParams.Unit]: cerberusInput.unit || undefined,
    }),
  };
};

export const getPropertyPreviewLegacyUrl = (
  cerberusInput: CerberusInputHcs,
): string => {
  const { slug, hcAddressId, streetAddress, zipcode, unit } = cerberusInput;
  const redirectUrlParams: { [key: string]: string } = {};
  if (hcAddressId) {
    redirectUrlParams.address_id = hcAddressId.toString();
  }
  if (slug) {
    redirectUrlParams.address_slug = slug;
  }
  if (streetAddress) {
    redirectUrlParams.street_address = streetAddress;
  }
  if (zipcode) {
    redirectUrlParams.zipcode = zipcode;
  }
  if (unit) {
    redirectUrlParams.unit = unit;
  }
  const url = `${LEGACY_PEXP_UI_URL}/search?${new URLSearchParams(
    redirectUrlParams,
  ).toString()}`;
  return url;
};

export const useNavigateToPropertyPreview = (): NavigateToPropertyPreviewFn => {
  const navigate = useNavigate();
  const experienceFlags = useExperienceFlags();
  return useCallback(
    (cerberusInput: CerberusInputHcs, blank?: boolean) => {
      if (experienceFlags.PEXP_REPORT_API) {
        const path = getPropertyPreviewPath(cerberusInput);
        return navigate(path);
      } else {
        const url = getPropertyPreviewLegacyUrl(cerberusInput);
        if (blank) {
          window.open(url, '_blank')?.focus();
        } else {
          window.open(url);
        }
      }
    },
    [experienceFlags, navigate],
  );
};
