import React, { useMemo, useState } from 'react';

import { ActionButtons } from '@hcs/design-system';
import { Anchor } from '@hcs/design-system';
import { OmAdminOrderItem, OrderItemAssignee } from '@hcs/types';

import { useAgileOpsAssignees } from '../../hooks/useAgileOpsAssignees';
import { useOmAdminUser } from '../../hooks/useOmAdminUser';
import { useOrderItemsAssign } from '../../hooks/useOrderItemsAssign';
import { sortedAssignees } from '../../utils';
import { AgileOpsAssigneesAutoComplete } from '../AgileOpsAssigneesAutoComplete';

import styles from './AgileOpsOrderItemsAssign.module.css';

type CallbackProp = (
  assignee: OrderItemAssignee | null,
  orderItemIds: number[],
) => void;
export interface AssignUserProps {
  orderItems: OmAdminOrderItem[];
  valueReportId?: number | null;
  className?: string;
  actionButtonsPortalId?: string;
  onSelect?: CallbackProp;
  onSubmit?: CallbackProp;
  onSuccess?: CallbackProp;
  onCancel?: VoidFunction;
}
const dataHcName = 'agile-ops-order-items-assign';
export const AgileOpsOrderItemsAssign = ({
  orderItems,
  valueReportId,
  className,
  actionButtonsPortalId,
  onSelect,
  onCancel,
  onSubmit,
  onSuccess,
}: AssignUserProps) => {
  const { data: omAdminUser } = useOmAdminUser();
  const { data: assigneesData } = useAgileOpsAssignees({ valueReportId });
  const assignees = useMemo(
    () => sortedAssignees(assigneesData),
    [assigneesData],
  );
  const { savedValue, orderItemIds } = useMemo(() => {
    let v: number | undefined | 'multi' | null;
    const ids: number[] = [];
    orderItems.forEach(({ assignee, id }) => {
      ids.push(id);
      const assigneeId = assignee?.id || null;
      if (v === undefined) {
        v = assigneeId;
      } else if (assigneeId !== v) {
        v = 'multi';
      }
    });
    return { savedValue: v, orderItemIds: ids };
  }, [orderItems]);
  const [value, setValue] = useState(savedValue);
  const assigneeSelected = assignees?.find((a) => a.id === value) || null;
  const assignUserMutation = useOrderItemsAssign({
    onSuccess: () => {
      onSuccess?.(assigneeSelected, orderItemIds);
    },
  });
  const handleSelect = (assignee: OrderItemAssignee | null) => {
    setValue(assignee?.id || null);
    onSelect?.(assignee, orderItemIds);
  };
  const handleSubmit = () => {
    if (orderItemIds.length && value !== 'multi' && value !== undefined) {
      assignUserMutation.mutate({
        assignee:
          value !== null
            ? {
                id: value,
                organizationId: assigneeSelected?.organizationId,
              }
            : null,
        orderItemIds,
      });
      onSubmit?.(assigneeSelected, orderItemIds);
    }
  };
  const assigneeSelf =
    assignees &&
    omAdminUser &&
    assignees.find((assignee) => assignee.id === omAdminUser.id);
  return (
    <>
      <AgileOpsAssigneesAutoComplete
        value={value}
        valueReportId={valueReportId}
        onSelect={handleSelect}
        className={className}
      />
      {assigneeSelf && (
        <Anchor
          dataHcName={`${dataHcName}-assign-to-self`}
          className={styles.AssignToSelf}
          onClick={() => {
            handleSelect(assigneeSelf);
          }}
          disabled={savedValue === omAdminUser.id}
        >
          Assign to me
        </Anchor>
      )}
      <ActionButtons
        portalIdRender={actionButtonsPortalId}
        dataHcName={`${dataHcName}-actions`}
        actions={[
          {
            dataHcName: `${dataHcName}-cancel`,
            label: 'Cancel',
            onClick: onCancel,
            secondary: true,
          },
          {
            dataHcName: `${dataHcName}-submit`,
            label: 'Assign',
            disabled: value === savedValue || assignUserMutation.isLoading,
            loading: assignUserMutation.isLoading,
            onClick: handleSubmit,
          },
        ]}
      />
    </>
  );
};
