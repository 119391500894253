import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import { OrderItemStatus } from '@hcs/types';
import { OmAdminOrderItem } from '@hcs/types';
import { formatTimeRemaining, nowKeywordToDate } from '@hcs/utils';
import { NULL_VALUE } from '@hcs/utils';

import { DisplayProps } from '.';

import styles from '../OmAdminOrderItemsTable.module.css';

const dataHcName = 'om-admin-order-items-table-cell';
const Display = ({ orderItem }: DisplayProps) => {
  const [timeRemaining, setTimeRemaining] = useState<string | null>(NULL_VALUE);
  const [isOverdue, setIsOverdue] = useState<boolean>(false);

  useEffect(() => {
    if (
      orderItem?.dueDate &&
      orderItem?.status !== OrderItemStatus.Cancelled &&
      orderItem?.status !== OrderItemStatus.Complete
    ) {
      setTimeRemaining(
        formatTimeRemaining(
          nowKeywordToDate('now'),
          nowKeywordToDate(orderItem.dueDate),
        ),
      );
    }
  }, [orderItem?.dueDate]);

  useEffect(() => {
    if (timeRemaining !== null && timeRemaining[0] === '-') {
      setIsOverdue(true);
    }
  }, [timeRemaining]);
  return (
    <div
      data-hc-name={`${dataHcName}-due-date`}
      className={classNames({ [styles.isOverdue]: isOverdue })}
    >
      {timeRemaining}
    </div>
  );
};

const HeaderCell = ({ ...tableHeaderCellProps }) => {
  return <TableHeaderCell {...tableHeaderCellProps}>Due Date</TableHeaderCell>;
};

const defaultHeaderCellProps: Partial<TableHeaderCellProps> = {
  isTableHeaderCell: true,
};

HeaderCell.defaultProps = defaultHeaderCellProps;

export type OmAdminOrderItemTableCellProps = TableCellProps & OmAdminOrderItem;

const ContentCell = ({
  orderItem,
  ...tableCellProps
}: {
  orderItem: OmAdminOrderItem;
}) => {
  return (
    <TableCell {...tableCellProps}>
      <Display orderItem={orderItem} />
    </TableCell>
  );
};

const defaultContentProps: Partial<TableCellProps> = {
  isTableCell: true,
};

ContentCell.defaultProps = defaultContentProps;

export const OM_ADMIN_ORDER_ITEMS_DUE_DATE_CONFIG = {
  HeaderCell,
  ContentCell,
  Display,
  label: 'Due Date',
};
