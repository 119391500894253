import React from 'react';
import { useNavigate } from 'react-router';

import { useAppConfigsExtended } from '@hcs/auth';
import { AppLauncher } from '@hcs/design-system';

import { PARTNER_TOOLS_APPS } from '../../apps';

const dataHcName = 'command-app-launcher';
export const PartnerToolsAppLauncher = () => {
  const navigate = useNavigate();
  const { data: appConfigsExtended } =
    useAppConfigsExtended(PARTNER_TOOLS_APPS);
  return (
    <AppLauncher
      dataHcName={dataHcName}
      appConfigsExtended={appConfigsExtended}
      onClickApp={(appConfigExtended) => {
        navigate(appConfigExtended.appConfig.rootPath);
      }}
      viewAll={{
        label: 'View All Internal Tools',
        onClick: () => {
          navigate('/');
        },
      }}
    />
  );
};
