import React from 'react';

export const ForgotPasswordPage = React.lazy(
  () => import('./ForgotPasswordPage'),
);
export const LoginPage = React.lazy(() => import('./LoginPage'));
export const PartnerToolsLandingPage = React.lazy(
  () => import('./PartnerToolsLandingPage'),
);
export const ResetPasswordPage = React.lazy(
  () => import('./ResetPasswordPage'),
);
