import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { useToastSlice } from '@hcs/toast';
import { OrderItemAssignee } from '@hcs/types';

import { OrderManagerReviewApi } from '../api/OrderManagerReviewApi.api';

export interface SubmitInspectionFormPayload {
  inspectionIdInt: number | null;
  formData: FormData | null;
}

export interface SubmitInspectionFormResponse {
  assignee: OrderItemAssignee;
  form: string;
  message: string | null;
  status: string;
}

export const useSubmitOmAdminOrderItemInspectionForm = () => {
  const {
    actions: { toastOpen },
  } = useToastSlice();
  return useMutation<
    SubmitInspectionFormResponse,
    AxiosError,
    SubmitInspectionFormPayload,
    void
  >(
    async ({ inspectionIdInt, formData }: SubmitInspectionFormPayload) => {
      if (!inspectionIdInt || !formData) {
        throw new Error('No form data provided');
      } else {
        return await OrderManagerReviewApi.submitOmAdminOrderItemInspectionForm(
          inspectionIdInt,
          formData,
        );
      }
    },
    {
      onError: (error) => {
        console.error(error);
        toastOpen({
          type: 'error',
          title: 'Error submitting inspection form',
        });
      },
      onSuccess: (newInspectionForm) => {
        if (newInspectionForm.status === 'invalid') {
          toastOpen({
            type: 'error',
            title: `${newInspectionForm.message}`,
          });
        }
        if (newInspectionForm.status === 'success') {
          toastOpen({
            type: 'success',
            title: `${newInspectionForm.message}`,
          });
        }
      },
    },
  );
};
