import React from 'react';

import { useIsLoggedIn } from '@hcs/authn';
import { MainNav } from '@hcs/design-system';
import { ChartIcon } from '@hcs/design-system';
import { HomeMagnifyingGlassIcon } from '@hcs/design-system';
import {
  APP_CONFIG_AGILE_SUITE,
  VIEW_PATHS_ORDER_MANAGER,
} from '@hcs/hc-products';
import { dataHcEventSectionReportSearch } from '@hcs/report-api';

const dataHcName = 'pexp-left-nav';
export const AgileSuiteNav = () => {
  const {
    data: { isLoggedIn },
  } = useIsLoggedIn();
  if (!isLoggedIn) return null;
  const NAV_ITEMS_CONFIG = [
    {
      label: 'Orders',
      navigateTo: `${APP_CONFIG_AGILE_SUITE.rootPath}${VIEW_PATHS_ORDER_MANAGER.ORDERS}`,
      dataHcName: `${dataHcName}-orders`,
      Icon: HomeMagnifyingGlassIcon,
      dataHcEventSection: dataHcEventSectionReportSearch,
    },
    {
      label: 'Report Summaries',
      navigateTo: `${APP_CONFIG_AGILE_SUITE.rootPath}${VIEW_PATHS_ORDER_MANAGER.REPORT_SUMMARY}`,
      dataHcName: `${dataHcName}-report-summaries`,
      dataHcEventSection: 'Report Summaries',
      Icon: ChartIcon,
    },
  ];
  return (
    <MainNav
      dataHcName={dataHcName}
      navItemsConfig={NAV_ITEMS_CONFIG}
      hoverToOpen
    />
  );
};
