import React, { useEffect, useState } from 'react';

import { TableCell, TableHeaderCell } from '@hcs/design-system';
import { TableCellProps, TableHeaderCellProps } from '@hcs/types';
import { OrderItemStatus } from '@hcs/types';
import { OmAdminOrderItem } from '@hcs/types';
import { formatTimeRemaining, nowKeywordToDate } from '@hcs/utils';
import { NULL_VALUE } from '@hcs/utils';

import { DisplayProps } from '.';

const dataHcName = 'om-admin-order-items-table-cell';
const Display = ({ orderItem }: DisplayProps) => {
  const [timeInQueue, setTimeInQueue] = useState<string | null>(NULL_VALUE);

  useEffect(() => {
    if (
      orderItem?.currentStatusDate &&
      orderItem?.status !== OrderItemStatus.Cancelled &&
      orderItem?.status !== OrderItemStatus.Complete
    ) {
      setTimeInQueue(
        formatTimeRemaining(
          nowKeywordToDate(orderItem.currentStatusDate),
          nowKeywordToDate('now'),
        ),
      );
    }
  }, [orderItem?.currentStatusDate]);

  return <div data-hc-name={`${dataHcName}-current-status`}>{timeInQueue}</div>;
};

const HeaderCell = ({ ...tableHeaderCellProps }) => {
  return <TableHeaderCell {...tableHeaderCellProps}>In Status</TableHeaderCell>;
};

const defaultHeaderCellProps: Partial<TableHeaderCellProps> = {
  isTableHeaderCell: true,
};

HeaderCell.defaultProps = defaultHeaderCellProps;

const ContentCell = ({
  orderItem,
  ...tableCellProps
}: {
  orderItem: OmAdminOrderItem;
}) => {
  return (
    <TableCell {...tableCellProps}>
      <Display orderItem={orderItem} />
    </TableCell>
  );
};

const defaultContentProps: Partial<TableCellProps> = {
  isTableCell: true,
};

ContentCell.defaultProps = defaultContentProps;

export const OM_ADMIN_ORDER_ITEMS_CURRENT_STATUS_CONFIG = {
  HeaderCell,
  ContentCell,
  Display,
  label: 'Current Status',
};
